<template>
  <div
    class="echart"
    id="echart-bar"
    :style="{float:'left',width: '100%', height: '400px'}"
  ></div>
</template>

<script>

const echarts = require("echarts") //echarts 5.0+ 不能用import

export default {
  name: 'ChartBar',
  methods: {
    initChart(data, source) {
      let getchart = echarts.init(document.getElementById('echart-bar'));
      var option = {
        title: {
          text: "Scratch图形化编程作品代码块使用情况统计",
          textAlign: 'center',
          left: '50%',
          top: 20,
          textStyle: {
            fontWeight: 'bold'
          }
        },
        grid: {
          bottom: 80 + 'px',
        },
        tooltip: {
          trigger: 'axis'
        },
        barWidth: 10,
        xAxis: [
          {
            type: 'category',
            //data: ['Scratch作品001','小猫踢球','Scratch作品002','Scratch作品003'],
            // data:[
            //     {value: 'Scratch作品001', textStyle: {width: 20}},
            //     {value: '小猫踢球', textStyle: {width: 20}},
            //     {value: 'Scratch作品002', textStyle: {width: 20}},
            //     {value: 'Scratch作品003', textStyle: {width: 20}}
            // ],
            data: data,
            axisLabel: {
              color: '#333',
              //  让x轴文字方向为竖向
              interval: 0,
              rotate: 40,
              overflow: 'truncate',
              ellipsis: '...',
              width: 100
              //   formatter: function (value) {
              //     return value.split('').join('\n')
              //   }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '',
          }
        ],
        series: [
          {
            name: '预计成绩',
            type: 'bar',
            //data:[20,40,60,80,100,120],
            data: source,
            itemStyle: {
              //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius: [15, 15, 0, 0]
              }
            },
            barGap: '0%'
          }
        ],
        //color:['#0078d7','#f49902']
      };

      getchart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        getchart.resize();
      });
    },

  }

}
</script>
