<template>
  <div class="my-work">
    <div
      class="mw-report"
      ref="myWork"
    >
      <h1
        class="mw-report-title"
        v-show="isDownload"
      >{{userInfo.realname}}的学习报告</h1>
      <div class="bg-white mw-statistics">
        <ul>
          <li><span><i class="mw-icon mw-time"></i>累计学习时长</span><label>{{userInfo.studyTime}}h</label></li>
          <li><span><i class="mw-icon mw-code"></i>累计代码行数</span><label>{{userInfo.codeLines}}行</label></li>
          <li><span><i class="mw-icon mw-work"></i>发布作品数量</span><label>{{userInfo.workNum}}个</label></li>
          <li><span><i class="mw-icon mw-support"></i>作品获赞次数</span><label>{{userInfo.starNum}}次</label></li>
          <li><span><i class="mw-icon mw-glance"></i>作品浏览量</span><label>{{userInfo.viewNum}}次</label></li>
        </ul>
      </div>
      <!-- <img data-v-a5ada37c="" style="width: 10px; height: 10px;" crossorigin="anonymous" src="https://teaching-works-public.oss-cn-shenzhen.aliyuncs.com/upload/test/005UJ76vly1h176wx6i3gj30b40b4aa9_1654594918668.jpeg" alt=""/> -->
      <div class="bg-gray mw-top5">
        <h1 class="mw-title">top5 作品</h1>
        <template>
          <div class="mw-works">
            <p
              class="no-data"
              v-if="dataList.length==0"
            >暂无数据！</p>
            <ProgramList
              :datas="dataList"
              :type="2"
              :noHover="true"
            ></ProgramList>
          </div>
        </template>

      </div>
      <div class="bg-white mw-pie">
        <div class="mw-cnt">
          <ChartPie ref="chart_pie_one" />
        </div>
      </div>
      <div class="bg-gray mw-bar">
        <div class="mw-cnt">
          <ChartBar ref="chart_bar_one" />
        </div>
      </div>
      <div
        class="bg-white mw-bar"
        style="height: 350px;"
      >
        <div class="mw-cnt">
          <ChartBar2 ref="chart_bar_two" />
        </div>
      </div>
      <!-- <div class="bg-gray mw-analyse">
        <h1 class="mw-title">作品分析报告</h1>
        <div class="mwa-cnt">
          「头衔」，当前您在平台上的热度值为「X」，排名「X」，请继续努力。<br/>
          您当前主要偏向使用「Python|编程工具，建议您您可以多尝试使用平台提供的其他实验室功能。<br/>
          针对图形化编程:Scratch提供了丰富的代码块，用于设计有趣的程序案例，通过分析:<br/>
          1.当前您使用较多的代码块是:XXX、XXX、XXXX(这里列举使用次数前10的代码块)。<br/>
          2.为了丰富程序，建议您在案例中加入:XXX、 XXXX、XXXX(这里列举使用次数最少的10个代码块)代码块。<br/>
        </div>
      </div> -->
      <div class="bg-white mw-statistics mw-statistics2">
        <ul>
          <li><span><i class="mw-icon mw-course-unit"></i>累计课程单元数</span><label>{{studyDataNumber.courseUnitNumber}}</label></li>
          <li><span><i class="mw-icon mw-class"></i>应上课次数</span><label>{{studyDataNumber.totalClassNumber}}</label></li>
          <li><span><i class="mw-icon mw-check"></i>正常考勤次数</span><label>{{studyDataNumber.normalClassNumber}}</label></li>
          <li><span><i class="mw-icon mw-absence"></i>缺勤次数</span><label>{{studyDataNumber.absentClassNumber}}</label></li>
          <li><span><i class="mw-icon mw-homework"></i>提交课程作品次数</span><label>{{studyDataNumber.absentWorkNumber}}</label></li>
        </ul>
      </div>
      <div class="bg-gray mw-record">
        <h1 class="mw-title">学习记录</h1>
        <el-table
          :data="historyList"
          height="300"
          row-class-name="mw-row"
          class="mwr-cnt mw-tb"
        >
        <el-table-column
            prop="infoName"
            label="开班名称"
            width="180"
          >
          <!-- <el-table-column
            prop="courseUnitWork"
            label="课程"
            width="180"
          > -->
          </el-table-column>
          <el-table-column
            prop="courseName"
            label="课程包"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="unitName"
            label="单元名称"
          >
          </el-table-column>
          <el-table-column
            prop="teacherRealName"
            label="授课老师"
          >
          </el-table-column>
          <el-table-column
            prop="classDateStr"
            label="上课日期"
          >
          </el-table-column>
          <el-table-column
            prop="classBeginTime"
            label="上课时间"
          >
          </el-table-column>
        </el-table>
      </div>
      <div class="bg-white mw-pie">
        <div class="mw-cnt">
          <ChartPie2 ref="chart_pie_two" />
        </div>
      </div>
      <div class="bg-gray mw-record">
        <h1 class="mw-title">获奖记录</h1>
        <el-table
          :data="awardList"
          height="300"
          row-class-name="mw-row"
          class="mwr-cnt mw-tb"
        >
          <el-table-column
            prop="unitName"
            label="单元名称"
          ></el-table-column>
          <el-table-column
            prop="teachName"
            label="授课老师"
          ></el-table-column>
          <el-table-column
            prop="propName"
            label="奖励"
          ></el-table-column>
          <el-table-column
            prop="propNumber"
            label="奖励次数"
          ></el-table-column>
          <el-table-column
            prop="integral"
            label="积分"
          ></el-table-column>
          <el-table-column
            prop="classBeginTime"
            label="上课日期"
          ></el-table-column>
          <el-table-column
            prop="classBeginTimeHours"
            label="上课时间"
          ></el-table-column>
        </el-table>
      </div>
      <!-- <div class="bg-white mw-analyse">
        <h1 class="mw-title">课程分析报告</h1>
        <div class="mwa-cnt">
          「头衔」，当前您在平台上的热度值为「X」，排名「X」，请继续努力。<br/>
          您当前主要偏向使用「Python|编程工具，建议您您可以多尝试使用平台提供的其他实验室功能。<br/>
          针对图形化编程:Scratch提供了丰富的代码块，用于设计有趣的程序案例，通过分析:<br/>
          1.当前您使用较多的代码块是:XXX、XXX、XXXX(这里列举使用次数前10的代码块)。<br/>
          2.为了丰富程序，建议您在案例中加入:XXX、 XXXX、XXXX(这里列举使用次数最少的10个代码块)代码块。<br/>
        </div>
      </div> -->
    </div>
    <div style="text-align: center; margin: 40px;">
      <el-button
        style="width: 500px"
        type="primary"
        @click="download"
      >下载报告</el-button>
    </div>
  </div>
</template>

<script>
import ProgramList from '@/components/ProgramList';
import Curriculums from '@/components/Curriculums';
import ChartBar2 from '@/components/user/ChartBar2'
import ChartPie from '@/components/user/ChartPie'
import ChartPie2 from '@/components/user/ChartPie2'
import ChartBar from '@/components/user/ChartBar'
import { getMyStudyData, getUserInfo, getWorks, getTop5, getPie2, getPie, getBar, getBar2, getMyLessonList } from "@/api/user"
import { getMyPropList } from '@/api/course'
import Vue from 'vue'
import html2canvas from "html2canvas"

export default {
  name: 'MyWork',
  components: {
    ProgramList,
    Curriculums,
    ChartBar,
    ChartBar2,
    ChartPie,
    ChartPie2
  },
  data() {
    return {
      isDownload: false,
      userInfo: {
        name: '',
        avatar: '',
        studyTime: 0,
        codeLines: 0,
        workNum: 0,
        starNum: 0,
        viewNum: 0,
      },
      queryParam: {
        uid: Vue.ls.get('Login_Userid'),
        pageSize: 5,
        pageNo: 1
      },
      dataList: [],
      historyParams: {
       // attend: 'normal',
        pageNo: 1,
        pageSize: 100
      },
      historyList: [],
      awardParam: {
        pageNo: 1,
        pageSize: 100
      },
      awardList: [],
      pie: {
        data: [],
        titles: []
      },
      pie2: {
        data: [],
        titles: []
      },
      studyDataNumber: {
        absentClassNumber: 0,
        absentWorkNumber: 0,
        courseUnitNumber: 0,
        normalClassNumber: 0,
        totalClassNumber: 0
      },
    };
  },
  created() {
    this.init();
    let name = '张雪'
    let xData = ['2020-02', '2020-03', '2020-04', '2020-05']
    let yData = [30, 132, 80, 134]
    this.historyList = [{ a: 'scratch', b: 'scratch启蒙课程', c: '小猫过河', d: '段老师', e: '2022-03-24', f: '15:40-16:40', g: '' }, { a: 'scratch', b: 'scratch启蒙课程', c: '小猫过河', d: '段老师', e: '2022-03-24', f: '15:40-16:40', g: '' }, { a: 'scratch', b: 'scratch启蒙课程', c: '小猫过河', d: '段老师', e: '2022-03-24', f: '15:40-16:40', g: '' }, { a: 'scratch', b: 'scratch启蒙课程', c: '小猫过河', d: '段老师', e: '2022-03-24', f: '15:40-16:40', g: '' }, { a: 'scratch', b: 'scratch启蒙课程', c: '小猫过河', d: '段老师', e: '2022-03-24', f: '15:40-16:40', g: '' }, { a: 'scratch', b: 'scratch启蒙课程', c: '小猫过河', d: '段老师', e: '2022-03-24', f: '15:40-16:40', g: '' }, { a: 'scratch', b: 'scratch启蒙课程', c: '小猫过河', d: '段老师', e: '2022-03-24', f: '15:40-16:40', g: '' }, { a: 'scratch', b: 'scratch启蒙课程', c: '小猫过河', d: '段老师', e: '2022-03-24', f: '15:40-16:40', g: '' }]

    // this.$nextTick(()=>{
    //   this.$refs.chart_bar_two.initChart(name,xData,yData) 
    // })
  },
  methods: {
    getBase64Image(url, ref) {
      var image = new Image();
      image.src = url + "?v=" + Math.random(); // 处理缓存
      image.crossOrigin = "*"; // 支持跨域图片
      image.onload = function () {
        var base64 = drawBase64Image(image);
        ref.src = base64;
      };
    },
    drawBase64Image(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      var dataURL = canvas.toDataURL("image/png");
      return dataURL;
    },
    init() {
      if (!Vue.ls.get('Login_Userid')) {
        this.$router.push(`/login?redirect_uri=${location.href}`)
      } else {
        this.getUserInfo();
        //this.getWorksData();
        this.getMyStudyData();
        this.getTop5Data();
        this.getPieData();
        this.getPie2Data();
        this.getBarData();
        this.getBar2Data();
        this.getHistory()
        this.getMyProps()
      }
    },
    getMyStudyData() {
      getMyStudyData().then(res => {
        if (res.success) {
          this.studyDataNumber = res.result;
        }
      })
    },
    getTop5Data() {
      this.dataList = [];
      getTop5().then(res => {
        if (res.success) {
          let data = res.result;
          this.dataList.push(...data.records.map(item => {
            return Object.assign({}, item, { star: false, collect: false, tags: [item.fileType_dictText, item.tagDifficulty] })
          }));
        }
      })
    },
    getPieData() {
      getPie().then(res => {
        if (res.success && res.result) {
          //this.pie.data = res.result
          let d = ['Scratch', 'Python', 'Python海龟', 'C', 'C++']
          let r = []
          let dt = []
          res.result.map(item => {
            let it = []
            for (let key in item) {
              it.push(item[key])
            }
            //console.log(item,dt)
            dt.push(item.language)
            r.push(it)
          })
          if (dt.length < 5) {
            let da = this.disArr(dt, d)
            da.map(item => {
              dt.push(item)
              r.push([item, 0, 0, 0, 0])
            })
          }
          console.log(dt, "pie data:", r)
          this.$refs.chart_pie_one.initChart(dt, r)
        }
      });
    },
    getPie2Data() {
      getPie2().then(res => {
        if (res.success && res.result) {
          let r = res.result
          let data = []
          for (let key in r) {
            let obj = {}
            if (key == 'CPP') obj.name = 'C++'
            else obj.name = key
            obj.value = r[key]
            data.push(obj)
          }
          this.$refs.chart_pie_two.initChart(data)
        }
      });
    },
    getBarData() {
      getBar().then(res => {
        if (res.success) {
          let r = res.result
          let data = []
          let source = []
          for (let key in r) {
            data.push(key)
            source.push(r[key])
          }
          this.$refs.chart_bar_one.initChart(data, source)
        }
      });
    },
    getBar2Data() {
      getBar2().then(res => {
        if (res.success) {
          let r = res.result
          let data = []
          for (let key in r) {
            let arr = []
            arr.push(r[key])
            arr.push(key)
            data.push(arr)
          }
          console.log("-------柱状图2:", data)
          // data = [
          //               //['lines', 'language'],
          //               [100, 'Python'],
          //               [200, 'Python海龟'],
          //               [300, 'C'],
          //               [300, 'C++']
          //           ]
          this.$refs.chart_bar_two.initChart(data)
        }
      });
    },
    getWorksData() {
      this.dataList = [];
      getWorks(this.queryParam).then(res => {
        if (res.success) {
          let data = res.result;
          //this.dataTotal = data.total;
          this.dataList.push(...data.records.map(item => {
            return Object.assign({}, item, { star: false, collect: false, tags: [item.fileType_dictText, item.tagDifficulty] })
          }));
        }
      })
    },
    disArr(oldData, newData) {
      let arr1 = new Set(oldData)//原数组
      let arr2 = new Set(newData)//新数组
      let deleteArr = []

      for (let k of arr2) {
        if (!arr1.has(k)) {
          deleteArr.push(k)
        }
      }
      return deleteArr // 获取差集
    },
    getHistory() {
      getMyLessonList(this.historyParams).then(res => {
        if (res.success) {
          this.historyList = res.result.records;
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }
      })
    },
    getMyProps() {
      this.awardParam.studentId = this.userInfo.id
      getMyPropList(this.awardParam).then(res => {
        if (res.success) {
          this.awardList = res.result.records
        }
      })
    },
    getUserInfo() {
      getUserInfo({ uid: Vue.ls.get('Login_Userid') }).then(res => {
        if (res.success) {
          this.userInfo = res.result;
        }
      })
    },
    download() {
      this.isDownload = true
      html2canvas(this.$refs.myWork, {allowTaint: true,useCORS:true, onrendered:()=>{
        document.querySelector('.mw-report').prepend(`<h1 class="mw-report-title">${this.userInfo.realname}的学习报告</h1>`)
        console.log("mw:",document.querySelector('.mw-report'))
      }}).then(canvas => {
        //回调中返回的其实就是一个canvas对象
        //可以使用canvas的toDataURL方法生成图片链接
        //url就是生成的链接可直接写入image标签的src中展示
        this.url = canvas.toDataURL("image/png");
        var link = document.createElement("a");
        link.href = this.url;
        link.download = (this.userInfo.realname || this.userInfo.name || '') + "作品分析";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.isDownload = false
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.my-work {
  text-align: center;
  //color: #464646;

  .bg-gray {
    background: #f7f7f7;
  }
  .mw-pie {
    height: 380px;
  }
  .mw-bar {
    height: 420px;
  }
  .mwr-cnt {
    width: 1200px;
    margin: 0px auto;
  }
  .mw-title {
    font-size: 20px;
    color: #464646;
    text-align: center;
    font-weight: 500;
  }

  .mw-icon {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    margin-right: 10px;
    position: relative;
    top: 2px;
  }
  .mw-time {
    background-image: url(~@/assets/image/user/time.png);
  }
  .mw-code {
    background-image: url(~@/assets/image/user/code.png);
  }
  .mw-work {
    background-image: url(~@/assets/image/user/work.png);
  }
  .mw-support {
    background-image: url(~@/assets/image/user/support.png);
  }
  .mw-glance {
    background-image: url(~@/assets/image/user/glance.png);
  }
  .mw-course-unit {
    background-image: url(~@/assets/image/user/course-unit.png);
  }
  .mw-class {
    background-image: url(~@/assets/image/user/class.png);
  }
  .mw-check {
    background-image: url(~@/assets/image/user/check.png);
  }
  .mw-absence {
    background-image: url(~@/assets/image/user/absence.png);
  }
  .mw-homework {
    background-image: url(~@/assets/image/user/homework.png);
  }
  .mw-statistics2 {
    height: 100px;
  }
  .mw-record {
    height: 360px;
    padding: 10px;
  }
  .mw-statistics ul,
  .mw-works,
  .mw-cnt {
    width: 1200px;
    margin: 0px auto;
  }

  .mw-works {
    padding: 10px 0px;

    .no-data {
      margin: 30px auto;
    }
  }

  .mw-analyse {
    padding: 10px 0px 25px;
    .mwa-cnt {
      text-align: center;
      color: #999999;
      font-size: 14px;
      line-height: 30px;
      margin-top: 20px;
      letter-spacing: 1px;
    }
  }

  .mw-statistics {
    padding: 25px 20px 20px;

    ul {
      display: flex;

      li {
        flex: 1;
        text-align: center;

        /*&:first-child{
          text-align: left;
        }

        &:last-child{
          text-align: right;
        }*/

        span {
          display: block;
        }
        label {
          display: block;
        }
      }
    }
  }

  .mw-top5 {
    padding: 10px;
  }

  .mw-tb {
  }
}
</style>
<style lang="scss">
.mw-tb {
  background-color: #f7f7f7 !important;

  &:before {
    display: none;
  }

  thead {
    color: #464646;
  }
  th.el-table__cell {
    background-color: #f7f7f7 !important;
  }
  td.el-table__cell {
    border-bottom: 6px solid #f7f7f7;
  }
  th.el-table__cell.is-leaf {
    border-bottom: 10px solid #f7f7f7;
  }
  .el-table__cell {
    padding: 10px 0px;
  }
}
.mw-report-title {
  background: #2481ef;
  line-height: 52px;
  color: #ffffff;
}
</style>
<style>
.my-work::-webkit-scrollbar {
  -webkit-appearance: none;
}
.my-work::-webkit-scrollbar:vertical {
  /* 设置垂直滚动条宽度 */
  width: 8px;
}

/* 这里不需要用到这个 */
.my-work::-webkit-scrollbar:horizontal {
  /* 设置水平滚动条厚度 */
  height: 80px;
}

.my-work::-webkit-scrollbar-thumb {
  /* 滚动条的其他样式定制，注意，这个一定也要定制，否则就是一个透明的滚动条 */
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
