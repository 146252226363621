<template>  
      <div class="echart" id="echart-pie2" :style="{float:'left',width: '100%', height: '350px'}"></div>   
</template>

<script>

const echarts = require("echarts") //echarts 5.0+ 不能用import

export default {
     name: 'ChartPie2',
      methods:{
      initChart(data) {
          let getchart = echarts.init(document.getElementById('echart-pie2'));
          var option = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                bottom: 0,
                left: 'center',
                data: ['Scratch', 'Python', 'Python海龟', 'C', 'C++']
            },
            series: [
                {
                //name: 'Access From',
                type: 'pie',
                radius: '50%',
                // data: [
                //     { value: 1048, name: 'Scratch' },
                //     { value: 735, name: 'Python' },
                //     { value: 580, name: 'Python海龟' },
                //     { value: 484, name: 'C' },
                //     { value: 300, name: 'C++' }
                // ],
                data: data,
                emphasis: {
                    itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
                }
            ]
            };

          getchart.setOption(option);
          //随着屏幕大小调节图表
          window.addEventListener("resize", () => {
            getchart.resize();
        });
      },

    }

}
</script>
