<template>  
      <div class="echart" id="echart-bar2" :style="{float:'left',width: '100%', height: '350px'}"></div>   
</template>

<script>

const echarts = require("echarts") //echarts 5.0+ 不能用import

export default {
     name: 'ChartBar2',
      methods:{
      initChart(data) {

          let getchart = echarts.init(document.getElementById('echart-bar2'));
          var  option = {
                title:{
                    text:"Python/C/C++代码行数统计",
                    textAlign: 'center',
                    left: '50%',
                    top: 20,
                    textStyle:{
                        fontWeight: 'bold'
                    }
                },
                dataset: {
                    // source: [
                    //     //['lines', 'language'],
                    //     [100, 'Python'],
                    //     [200, 'Python海龟'],
                    //     [300, 'C'],
                    //     [300, 'C++']
                    // ]
                    source: data
                },
                barWidth: 10,
                grid: { containLabel: true },
                xAxis: {  },
                yAxis: { 
                    type: 'category',
                    axisLine: {
                        show: false,
                        // lineStyle: {
                        //     color: '#ff0000'
                        // }
                    },
                    splitLine:{
                        show: false
                    }
                },
                // visualMap: {
                //     orient: 'horizontal',
                //     left: 'center',
                //     min: 10,
                //     max: 100,
                //     text: ['High Score', 'Low Score'],
                //     // Map the score column to color
                //     dimension: 0,
                //     inRange: {
                //         color: ['#65B581', '#FFCE34', '#FD665F']
                //     }
                // },
                series: [
                    {
                    type: 'bar',
                    //data:[0,50,100,150,200,250,300,350,400,450,500,550,600,650],
                    encode: {
                        x: 'lines',
                        y: 'language'
                    }
                    }
                ]
                };

          getchart.setOption(option);
          //随着屏幕大小调节图表
          window.addEventListener("resize", () => {
            getchart.resize();
        });
      },

    }

}
</script>
