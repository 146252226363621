<template>  
      <div class="echart" id="echart-pie" :style="{float:'left',width: '100%', height: '350px'}"></div>   
</template>

<script>

const echarts = require("echarts") //echarts 5.0+ 不能用import

export default {
     name: 'ChartPie',
      methods:{
      initChart(data, source) {
          let getchart = echarts.init(document.getElementById('echart-pie'));
          var option = {
            legend: {
                bottom: 0,
                left: 'center',
                //data: ['Scratch', 'Python', 'Python海龟', 'C', 'C++']
                data: data
            },
            title: [
                {
                    subtext: '作品类型占比',
                    left: '9.5%',
                    top: '75%',
                    textAlign: 'center',
                    subtextStyle: {
                        fontWeight: 'bold'
                    }
                },
                {
                    subtext: '获得点赞作品类型占比',
                    left: '35.5%',
                    top: '75%',
                    textAlign: 'center',
                    subtextStyle: {
                        fontWeight: 'bolder'
                    }
                },
                {
                    subtext: '作品类型代码行数占比',
                    left: '61.5%',
                    top: '75%',
                    textAlign: 'center',
                    subtextStyle: {
                        fontWeight: 'blod'
                    }
                },
                {
                    subtext: '作品获得浏览量占比',
                    left: '88%',
                    top: '75%',
                    textAlign: 'center',
                    subtextStyle: {
                        fontWeight: 'blod'
                    }
                }
            ],
            tooltip: {},
            dataset: {
                dimensions: ['occupy','作品类型占比', '获得点赞作品类型占比', '作品类型代码行数占比', '作品获得浏览量占比'],
                // source: [
                //     ['Scratch', 10, 10, 0, 0,],
                //     ['Python', 86.5, 0, 85.7, 83.1],
                //     ['Python海龟', 0, 30.4, 65.1, 53.3],
                //     ['C', 24.1, 67.2, 79.5, 86.4],
                //     ['C++', 55.2, 67.1, 69.2, 10]
                // ]
                source: source
            },
            series: [
                {
                    type: 'pie',
                    radius: '100',
                    center: ['10%', '40%'],
                    encode:{
                        itemName: 'occupy',
                        value: '作品类型占比'
                    }
                },
                {
                type: 'pie',
                radius: '100',
                center: ['36%', '40%'],
                encode: {
                    itemName: 'occupy',
                    value: '获得点赞作品类型占比'
                    }
                },
                {
                type: 'pie',
                radius: '100',
                center: ['62%', '40%'],
                encode: {
                    itemName: 'occupy',
                    value: '作品类型代码行数占比'
                }
                },
                {
                type: 'pie',
                radius: '100',
                center: ['88%', '40%'],
                encode: {
                    itemName: 'occupy',
                    value: '作品获得浏览量占比'
                }
                }
            ]
            };

          getchart.setOption(option);
          //随着屏幕大小调节图表
          window.addEventListener("resize", () => {
            getchart.resize();
        });
      },

    }

}
</script>
